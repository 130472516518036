import React from "react";
import { Button, Container } from "react-bootstrap";
import "../Style/buttonScrollForm.css";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

const ScrollToContactButton = () => {
  const scrollToContact = () => {
    const contactForm = document.getElementById("SectionForm");
    if (contactForm) {
      contactForm.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Container className="d-flex align-items-center justify-content-center pb-1">
      <div className="text-white me-2 d-none d-sm-flex align-items-center justify-content-center">
        Entra nel mondo degli investimenti immobiliari
      </div>
      <Button
        className="button-ScrollForm d-flex align-items-center justify-content-center"
        onClick={scrollToContact}
      >
        Fissa una chiamata GRATUITA
        <MdKeyboardDoubleArrowRight className="ms-1 fs-5" />
      </Button>
    </Container>
  );
};

export default ScrollToContactButton;
