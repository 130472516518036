import React, { useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { userPost } from "../../reducer/userSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "../../Style/pageSecondary.css";
import { toast } from "react-toastify";
import validator from "validator";
import SpinnerLoading from "../SpinnerLoading";
import ReCAPTCHA from "react-google-recaptcha";
import { Accordion } from "react-bootstrap";

const RegistrationModal = () => {
  const [show, setShow] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [privacyAccepted, setPrivacyAccepted] = useState(false);
  const [emailAccepted, setEmailAccepted] = useState(false);

  // Nuovo stato per mostrare/nascondere la password
  const [showPassword, setShowPassword] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const dispatch = useDispatch();

  const role = useRef(null);
  const name = useRef(null);
  const surname = useRef(null);
  const phoneNumber = useRef(null);
  const password = useRef(null);
  const email = useRef(null);
  const address = useRef(null);
  const birthdayDate = useRef(null);
  const avatar = useRef(null);
  const experienceInvestiment = useRef(null);
  const motivationToInvestiment = useRef(null);
  const contactSociety = useRef(null);
  const dispositionCapital = useRef(null);
  const city = useRef(null); // Nuovo campo per la città
  const gender = useRef(null); // Nuovo campo per il genere

  const validateRegistrationForm = (data) => {
    let errors = {};
    if (!validator.isLength(data.name, { min: 1 }))
      errors.name = "Il nome è richiesto";
    if (!validator.isLength(data.surname, { min: 1 }))
      errors.surname = "Il cognome è richiesto";
    if (!validator.isMobilePhone(data.phoneNumber, "any"))
      errors.phoneNumber = "Il numero di telefono non è valido";
    if (!validator.isLength(data.password, { min: 6 }))
      errors.password = "La password deve essere lunga almeno 6 caratteri";
    if (!validator.isEmail(data.email)) errors.email = "L'email non è valida";

    if (!privacyAccepted)
      errors.privacyAccepted = "Devi accettare i termini sulla privacy";
    if (!emailAccepted)
      errors.emailAccepted = "Devi accettare di ricevere email di sistema";

    return errors;
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const data = {
      role: role.current.value,
      name: name.current.value,
      surname: surname.current.value,
      phoneNumber: phoneNumber.current.value,
      password: password.current.value,
      email: email.current.value,
      address: address.current.value,
      birthdayDate: birthdayDate.current.value,
      avatar: avatar.current.files[0],
      experienceInvestiment: experienceInvestiment.current.value,
      motivationToInvestiment: motivationToInvestiment.current.value,
      contactSociety: contactSociety.current.value,
      dispositionCapital: dispositionCapital.current.value,
      city: city.current.value, // Nuovo campo città
      gender: gender.current.value, // Nuovo campo genere
      recaptchaToken,
    };

    const formErrors = validateRegistrationForm(data);
    setErrors(formErrors);

    if (Object.keys(formErrors).length !== 0) {
      const errorMessage = Object.values(formErrors)[0];
      toast.error(errorMessage, { position: "top-right", autoClose: 3000 });
      setIsLoading(false);
      return;
    }

    dispatch(userPost(data))
      .unwrap()
      .then((originalPromiseResult) => {
        if (originalPromiseResult) {
          toast.success(
            "Registrazione Effettuata con Successo! 👌 Controlla la tua email per confermare l'account.",
            { position: "top-right", autoClose: 6000 }
          );
          handleClose();
        } else {
          toast.error("Errore!! è già presente un account con questa email.", {
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.error(rejectedValueOrSerializedError);
        toast.error("Si è verificato un errore durante l'invio del form.", {
          position: "top-right",
          autoClose: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Button
        className=" style-button-association shadow "
        onClick={handleShow}
      >
        Registrati
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>FORM DI REGISTRAZIONE </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex flex-column align-items-center">
          <Form>
            <Form.Group className="mb-3" controlId="formGroupRole">
              <Form.Control
                type="hidden"
                ref={role}
                readOnly
                defaultValue="investitore"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupName">
              <Form.Label>NOME</Form.Label>
              <Form.Control type="input" ref={name} placeholder="nome" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupSurname">
              <Form.Label>COGNOME</Form.Label>
              <Form.Control type="input" ref={surname} placeholder="cognome" />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formGroupNumber">
              <Form.Label>
                NUMERO DI TELEFONO (con prefisso nazionale es:+39)
              </Form.Label>
              <Form.Control
                type="input"
                ref={phoneNumber}
                placeholder="Numero di telefono"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupEmail">
              <Form.Label>EMAIL</Form.Label>
              <Form.Control
                type="email"
                ref={email}
                placeholder="inserisci un indirizzo email"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGroupPassword">
              <Form.Label>PASSWORD</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"} // Cambia tipo di input
                ref={password}
                placeholder="inserisci una password"
              />
              <Button
                variant="link"
                onClick={() => setShowPassword(!showPassword)} // Mostra/nascondi la password
              >
                {showPassword ? "Nascondi" : "Mostra"} password
              </Button>
            </Form.Group>

            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Informazioni facoltative</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="mb-3" controlId="formGroupAddress">
                    <Form.Label>INDIRIZZO</Form.Label>
                    <Form.Control
                      type="input"
                      ref={address}
                      placeholder="indirizzo di residenza"
                    />
                  </Form.Group>

                  {/* Nuovo campo città */}
                  <Form.Group className="mb-3" controlId="formGroupCity">
                    <Form.Label>CITTÀ DI RESIDENZA</Form.Label>
                    <Form.Control type="input" ref={city} placeholder="Città" />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupDate">
                    <Form.Label>DATA DI NASCITA</Form.Label>
                    <Form.Control
                      type="date"
                      ref={birthdayDate}
                      placeholder="description association"
                    />
                  </Form.Group>
                  {/* Nuovo campo genere */}
                  <Form.Group className="mb-3" controlId="formGroupGender">
                    <Form.Label>GENERE </Form.Label>
                    <Form.Control as="select" ref={gender}>
                      <option value="">Seleziona il tuo genere</option>
                      <option value="Maschio">Maschio</option>
                      <option value="Femmina">Femmina</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupImg">
                    <Form.Label>IMMAGINE PROFILO </Form.Label>
                    <Form.Control type="file" ref={avatar} />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>
                      {" "}
                      Hai già fatto investimenti immobiliari?
                    </Form.Label>
                    <Form.Control type="text" ref={experienceInvestiment} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupExp">
                    <Form.Label>
                      {" "}
                      Perchè vorresti fare un investimento immobiliare?
                    </Form.Label>
                    <Form.Control type="text" ref={motivationToInvestiment} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupContact">
                    <Form.Label>
                      {" "}
                      In che modo hai conosciuto la nostra società?{" "}
                    </Form.Label>
                    <Form.Control type="text" ref={contactSociety} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formGroupCapital">
                    <Form.Label>
                      {" "}
                      Qual'è il tuo capitale a disposizione?
                    </Form.Label>
                    <Form.Control type="number" ref={dispositionCapital} />
                  </Form.Group>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>

            {/* Checkbox Privacy */}
            <Form.Group
              controlId="privacyCheckbox"
              className="d-flex align-items-center justify-content-start mt-4"
            >
              <Form.Check
                type="checkbox"
                onChange={(e) => setPrivacyAccepted(e.target.checked)}
                isInvalid={!!errors.privacyAccepted}
              />
              <Form.Label>
                <span className="mx-2">
                  Accetto i termini della
                  <a
                    className="ms-2"
                    href="https://www.iubenda.com/privacy-policy/54067366"
                    class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe "
                    title="Privacy Policy "
                  >
                    Privacy Policy
                  </a>
                </span>
              </Form.Label>
            </Form.Group>

            {/* Checkbox Email */}
            <Form.Group controlId="emailCheckbox" className="my-3">
              <Form.Check
                type="checkbox"
                label="Accetto di ricevere email automatiche del server per il corretto funzionamento della piattaforma"
                onChange={(e) => setEmailAccepted(e.target.checked)}
                isInvalid={!!errors.emailAccepted}
              />
            </Form.Group>

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} // Sostituisci con il tuo Site Key
              onChange={handleRecaptchaChange} // Gestore per il cambio del token
            />

            <div className="text-center my-4">
              <Button
                variant="success"
                className="text-light fw-bold"
                onClick={handleSubmit}
              >
                REGISTRATI
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center">
          {isLoading === true ? (
            <p className="d-flex align-items-center justify-content-center">
              Attendi..
              <SpinnerLoading />
            </p>
          ) : (
            <span></span>
          )}
        </div>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RegistrationModal;
