import React from "react";
import Card from "react-bootstrap/Card";
import article23Img from "../../asset/article23-img.png";

import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Article23() {
  const navigate = useNavigate();

  const redirectArticle23 = () => {
    navigate("/superbonus-rendite-catastali");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article23Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Superbonus e rendite catastali, in arrivano le lettere dell’Agenzia
            delle Entrate
          </Card.Title>

          <Button onClick={redirectArticle23} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article23;
