import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import NavigationBar from "../../Components/NavigationBar";
import Footer from "../../Components/Footer";
import { useNavigate } from "react-router-dom";
import BarraLaterale from "../../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../../Style/pageSecondary.css";
import { FaArrowLeft } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import article23Img from "../../asset/article23-img.png";
import newsletter23img from "../../asset/newsletter23-img.png";

const Article23Text = () => {
  const navigate = useNavigate();

  const redirectBlogOnClick = () => {
    navigate("/articoli-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>
          Superbonus e rendite catastali, in arrivano le lettere dell’Agenzia
          delle Entrate
        </title>
        <meta
          name="description"
          content="Chi ha ristrutturato il proprio immobile beneficiando
            del superbonus 110 deve procedere all’aggiornamento delle rendite
            catastali"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/superbonus-rendite-catastali"
        />
      </Helmet>

      <BarraLaterale />
      <Container
        fluid
        className="mt-5 pt-5  p-0 d-flex align-items-center justify-content-center bg-white"
      >
        <img src={article23Img} alt="" className=" w-50   mt-4  " />
      </Container>
      <Container fluid className="bg-white p-0">
        <Container fluid className="py-5 color-blunav">
          <h1 className="  fs-3 text-white  text-center p-lg-5  ">
            Superbonus e rendite catastali, in arrivano le lettere dell’Agenzia
            delle Entrate
          </h1>
        </Container>
        <Container className="my-4 ">
          <p className="colortext-blunav fs-5 line-height2  text-center-mobile ">
            Chi ha ristrutturato il proprio immobile beneficiando
            del superbonus 110 deve procedere all’aggiornamento delle rendite
            catastali. I contribuenti che non hanno ancora fatto l’adeguamento
            riceveranno una lettera da parte dell’Agenzia delle
            Entrate successivamente all’incrocio dei dati. Come spiegato
            dal Sole 24 Ore, lo ha anticipato il direttore uscente delle
            Entrate, Ernesto Maria Ruffini, nella relazione di fine anno. 
          </p>
          <p className="colortext-blunav fs-5 line-height2 text-center-mobile ">
            Nello specifico, Ruffini aveva fatto sapere che “nell’ambito delle
            attività finalizzate all’aggiornamento della banca dati catastale”,
            ci saranno le attività ordinarie di verifica e controllo, in più nel
            2025 ci sarà anche “l’avvio di una campagna di compliance relativa
            al superbonus”. Con le sue lettere l’Agenzia delle Entrate chiede di
            fatto ai contribuenti di spiegare la propria posizione. Non si
            tratterà, dunque, del formale avvio di un controllo. 
          </p>
        </Container>
        <Container className="d-flex align-content-center justify-content-center">
          <img
            src={newsletter23img}
            alt="immagine24"
            className="shadow mediaQuery-w70"
            data-aos="fade-up"
            data-aos-duration="2000"
          />
        </Container>
        <Container data-aos="fade-down" data-aos-duration="2000">
          <h3 className="colortext-blunav fs-3 line-height2 bold-600 text-center mt-5 mb-3">
            Superbonus aggiornamento rendite catastali, quando saranno inviate
            le lettere e cosa fare
          </h3>
          <p className="colortext-blunav fs-5 line-height2  text-center-mobile ">
            Le lettere dell’Agenzia delle Entrate per l’aggiornamento delle
            rendite catastali successivo a interventi con il
            superbonus partiranno dopo l’incrocio dei dati. In particolare,
            verranno confrontate “le comunicazioni dell’opzione relativa agli
            interventi di recupero del patrimonio edilizio, efficienza
            energetica, rischio sismico, impianti fotovoltaici e colonnine di
            ricarica” e “le risultanze della banca dati catastale, per gli
            immobili per i quali non risulta essere stata presentata, ove
            prevista la dichiarazione di variazione catastale”. 
          </p>
          <p className="colortext-blunav fs-5 line-height2  text-center-mobile ">
            {" "}
            Come spiegato dal Sole 24 Ore, l’avviso arriverà “a chi ha avviato
            una pratica di superbonus e ha ceduto il credito, ma non ha
            presentato la variazione catastale”. Ricevuta la lettera, però, sarà
            possibile “dimostrare la correttezza del proprio operato, spiegando
            perché la variazione catastale non è stata effettuata”.  Ma quanti
            potrebbero essere gli interessati? In base a quanto indicato
            dall’Enea, sono 496.963 gli edifici nei quali si è intervenuto con
            il superbonus. Potenzialmente, dunque, si tratta di numeri elevati.
          </p>
        </Container>

        <Container className="py-5">
          <Button
            onClick={redirectBlogOnClick}
            className="d-flex align-items-center justify-content-center button-redLogo"
          >
            <FaArrowLeft className="fs-4" />
            Indietro
          </Button>
        </Container>
      </Container>

      <Footer />
    </>
  );
};
export default Article23Text;
