import React from "react";
import { Button, Container } from "react-bootstrap";
import LogoPng from "../asset/logo-srl-png.png";
import "../Style/intro.css";
import background from "../asset/background-intro.png";
import backgroundMobile from "../asset/intro-mobileScura.png";
import { useNavigate } from "react-router-dom";
import backgroundScuro from "../asset/background-introScura.jpg";

const Intro = () => {
  const introStyle = {
    backgroundImage: `url(${backgroundScuro})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "80vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const introStyleMobile = {
    backgroundImage: `url(${backgroundMobile})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",

    minHeight: "80vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const navigate = useNavigate();

  const redirectContactOnClick = () => {
    navigate("/contatti");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Container
        fluid
        style={window.innerWidth < 768 ? introStyleMobile : introStyle} // Usa introMobileStyle per schermi mobili
        className="overflowHiddenIntro fade-in "
      >
        <div className="mt-3 d-flex flex-column justify-content-end align-items-end color-title text-center w-100  overflowHiddenIntro logo-container img-zoom">
          <img className="logo-welcome mt-5 pt-5  " src={LogoPng} alt="logo" />
        </div>
        <div className="mt-5 py-5 d-flex flex-column justify-content-end px-5 align-items-center color-title text-center gap-3 h-100 colortext-blunav  ">
          <p className=" text-center bold-600 pt-sm-4 pt-5 text-white fs-5  ">
            Siamo una società di investimenti immobiliari che sviluppa
            opportunità esclusive, generando importanti rendimenti per chi
            investe con noi fiducia e denaro, offrendo ai nostri partner la
            possibilità di partecipare a progetti di valore. Con la nostra
            esperienza e dedizione, assicuriamo rendimenti significativi e
            solidi.
          </p>
        </div>
      </Container>
    </>
  );
};

export default Intro;
