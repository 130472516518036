import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, FormGroup } from "react-bootstrap";
import { nanoid } from "nanoid";
import { useDispatch, useSelector } from "react-redux";
import { getUsers, filterUserSurname } from "../reducer/userSlice";

import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import SingleUser from "../Components/UserBackendComponents/SingleUser";
import SpinnerLoading from "../Components/SpinnerLoading";
import { useNavigate } from "react-router-dom";
import { MdSearch } from "react-icons/md";
import "../Style/pageSecondary.css";
import { IoBarChartSharp } from "react-icons/io5";

const UtentGestion = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { usersArray } = useSelector((state) => state.users);
  console.log(usersArray);

  const redirectInvestimentGestionOnClick = () => {
    navigate("/gestionInvestiment");
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  const handleSearch = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term === "") {
      // Se la barra di ricerca è vuota, mostra tutti gli utenti
      dispatch(getUsers());
    } else {
      // Altrimenti, filtra gli utenti per cognome
      dispatch(filterUserSurname(term));
    }
  };

  return (
    <>
      <NavigationBar />

      <Container className="mt-5 pt-5">
        <Button
          className="button-redLogo mb-5 mt-5 d-flex justify-content-center align-items-center"
          onClick={redirectInvestimentGestionOnClick}
        >
          <IoBarChartSharp className="fs-5 me-2" />
          Gestione Investimenti
        </Button>
        <h1 className="text-center mb-3 colortext-blunav">Gestione utenti </h1>
        <div className="d-flex justify-content-center align-items-center  mb-5">
          <MdSearch className="fs-1 me-2 colortext-blunav" />

          <Form.Group controlId="search" className="w-70 searchBarStyle">
            <Form.Control
              className=" shadow"
              type="text"
              placeholder="Cerca per cognome"
              value={searchTerm}
              onChange={handleSearch} // Funzione che gestisce il cambio del termine di ricerca
            />
          </Form.Group>
        </div>
      </Container>
      <Container>
        <Row className="d-flex justify-content-center align-items-center ">
          {usersArray.length > 0 ? (
            usersArray.map((user) => {
              return (
                <SingleUser
                  key={nanoid()}
                  user={user}
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                />
              );
            })
          ) : (
            <div className="my-5 d-flex justify-content-center align-items-center">
              <SpinnerLoading />
            </div>
          )}
        </Row>
      </Container>
      <Footer />
    </>
  );
};

export default UtentGestion;
