import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Pagination } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import BarraLaterale from "../Components/BarraLaterale";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";
import { Helmet } from "react-helmet";
import blogBGIntro from "../asset/bg-blogZoom.png";

import Article1 from "../Components/ArticleBlog/Article1";
import Article2 from "../Components/ArticleBlog/Article2";
import Article3 from "../Components/ArticleBlog/Article3";
import Article4 from "../Components/ArticleBlog/Article4";
import Article5 from "../Components/ArticleBlog/Article5";
import Article6 from "../Components/ArticleBlog/Article6";
import Article7 from "../Components/ArticleBlog/Article7";
import Article8 from "../Components/ArticleBlog/Article8";
import Article9 from "../Components/ArticleBlog/Article9";
import Article10 from "../Components/ArticleBlog/Article10";
import Article11 from "../Components/ArticleBlog/Article11";
import Article12 from "../Components/ArticleBlog/Article12";
import Article13 from "../Components/ArticleBlog/Article13";
import Article14 from "../Components/ArticleBlog/Article14";
import Article15 from "../Components/ArticleBlog/Article15";
import Article16 from "../Components/ArticleBlog/Article16";
import Article17 from "../Components/ArticleBlog/Article17";
import Article18 from "../Components/ArticleBlog/Article18";
import Article19 from "../Components/ArticleBlog/Article19";
import Article20 from "../Components/ArticleBlog/Article20";
import Article21 from "../Components/ArticleBlog/Article21";
import Article22 from "../Components/ArticleBlog/Article22";
import Article23 from "../Components/ArticleBlog/Article23";
import Article24 from "../Components/ArticleBlog/Article24";
import Article25 from "../Components/ArticleBlog/Article25";

const articles = [
  <Article1 />,
  <Article2 />,
  <Article3 />,
  <Article4 />,
  <Article5 />,
  <Article6 />,
  <Article7 />,
  <Article8 />,
  <Article9 />,
  <Article10 />,
  <Article11 />,
  <Article12 />,
  <Article13 />,
  <Article14 />,
  <Article15 />,
  <Article16 />,
  <Article17 />,
  <Article18 />,
  <Article19 />,
  <Article20 />,
  <Article21 />,
  <Article22 />,
  <Article23 />,
  <Article24 />,
  <Article25 />,
].reverse();

const Blog = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 6;

  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = articles.slice(
    indexOfFirstArticle,
    indexOfLastArticle
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>
          11.22 sviluppo immobiliare-azienda di investimenti immobiliari
        </title>
        <meta
          name="description"
          content="11.22 Sviluppo Immobiliare Srl è una società di investimento immobiliare, che propone operazioni profittevoli in maniera completamente passiva"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/articoli-investimenti-immobiliari"
        />
      </Helmet>
      <BarraLaterale />
      <Container
        fluid
        style={{
          backgroundImage: `url(${blogBGIntro})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          minHeight: "50vh",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingBottom: "3rem",
        }}
        className="img-zoom"
      >
        <div className="mt-5 pt-5 d-flex flex-column justify-content-center align-items-center color-title text-center fade-in">
          <h1 className="mt-5 fw-bold text-white fontTitle-size-sm">
            APPROFONDIMENTI
          </h1>
        </div>
      </Container>
      <Container fluid className="py-1 color-blunav min-w100">
        <p className="fs-4 text-white text-center p-lg-5">
          In questa sezione trovi gli articoli contenenti interessanti
          informazioni sul mondo Immobiliare
          <hr />
          <span className="fs-4">
            Se sei un operatore del settore Immobiliare e sei interessato a
            scrivere un articolo, invialo al nostro indirizzo mail
            info@1122sviluppoimmobiliare.com e il nostro team valuterà la
            pubblicazione sul nostro sito.
          </span>
        </p>
      </Container>
      <Container className="my-5">
        <Row>{currentArticles}</Row>
        <Pagination className="justify-content-center">
          {[...Array(Math.ceil(articles.length / articlesPerPage)).keys()].map(
            (number) => (
              <Pagination.Item
                key={number + 1}
                active={number + 1 === currentPage}
                onClick={() => paginate(number + 1)}
              >
                {number + 1}
              </Pagination.Item>
            )
          )}
        </Pagination>
      </Container>
      <Footer />
    </>
  );
};

export default Blog;
