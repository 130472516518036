import React from "react";
import { Container } from "react-bootstrap";
import imgCallNumberGreen from "../asset/numeroVerde1122SviluppoImmobiliare.png";
import { FaArrowDown } from "react-icons/fa";

const CallToActionNumberGreen = () => {
  const phoneNumber = "800160722";

  const styleNumber = {
    width: "20rem",
  };

  return (
    <Container className="d-flex justify-content-center align-items-center flex-column">
      <p className="text-center mt-5 fs-5 d-flex justify-content-center align-items-center flex-column colortext-blunav bold-600">
        Per avere maggiori informazioni o assistenza chiama il nostro numero
        verde gratuito
        <FaArrowDown className="fs-2 mt-4" />
      </p>
      <a href={`tel:${phoneNumber}`} style={{ textDecoration: "none" }}>
        <img
          style={styleNumber}
          src={imgCallNumberGreen}
          alt="immagine numero verde"
        />
      </a>
    </Container>
  );
};

export default CallToActionNumberGreen;
