import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import ContactFormSmall from "../Components/ContactFormSmall";
import BarraLaterale from "../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";

import backMissionIntro from "../asset/missionBG-80op.png";
import trustImg from "../asset/fiduciaCliente.png";
import SecurityInvestimentImg from "../asset/sicurityInvestiment.png";
import City from "../asset/city.png";

import CallToActionNumberGreen from "../Components/CallToActionNumberGreen";
import bgClassEnergy from "../asset/caseGreen5.jpg";
import ContactForm from "../Components/ContactForm";
import { Helmet } from "react-helmet";

const LaNostraMission = () => {
  const MissionIntroPageStyle = {
    backgroundImage: `url(${backMissionIntro})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh", // Imposta l'altezza minima al 100% della viewport
    display: "flex",
    backgroundColor: "white",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "3rem",
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>La missione della 1122 sviluppo immobiliare</title>
        <meta
          name="description"
          content="La Missione della società 11.22 Sviluppo Immobiliare Srl è  rendere accessibile il settore degli investimenti immobiliari a tutti per generare una rendita passiva."
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/laNostraMission-rendita-passiva"
        />
      </Helmet>

      <BarraLaterale />
      <Container
        fluid
        style={MissionIntroPageStyle}
        className="img-zoom overflowHidden"
      >
        <div
          className="mt-5 pt-5 d-flex flex-column justify-content-center
         align-items-center  text-center fade-in h-100 "
        >
          <h1 className="mt-5 fw-bold text-white ">LA NOSTRA MISSION </h1>
        </div>
      </Container>
      <Container fluid className="py-5 color-blunav overflowHidden ">
        <p className="  fs-5 text-white  text-center p-lg-5 ">
          <span className="  fs-4 d-block">
            La Mission della società 11.22 Sviluppo Immobiliare Srl è quella di
            rendere accessibile il settore degli investimenti immobiliari a
            tutti coloro che non dispongono di grandi capitali, così da poter
            offrire l’opportunità di entrare in questo settore ed ottenere delle
            rendite profittevoli.
          </span>{" "}
          <hr />
          Offriamo l'opportunità di entrare in questo settore e ottenere rendite
          profittevoli, guidando i nostri investitori lungo l'intero percorso
          con il supporto necessario per massimizzare i risultati. Attraverso
          una prospettiva di medio-lungo termine, ci impegniamo a creare un
          percorso di successo sostenibile e duraturo.
        </p>
      </Container>
      <Container fluid className="overflowHidden bg-bluScuro  shadow py-4">
        <Container>
          <Row className=" d-flex align-items-center justify-content-start ">
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-offset="200"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h2 className="text-center bold-600 color-title-chiaro fs-1 mb-4 red-logo">
                  Fiducia
                </h2>
                <p className="text-white  fs-5 w-100 .line-height3">
                  Il rapporto che instauriamo con i nostri investitori è basato
                  sulla fiducia, ripagata seguendo e curando l’investimento
                  immobiliare in ogni suo dettaglio, affinché possa offrire il
                  massimo profitto.
                  <hr />
                  Siamo fortemente ancorati ai valori tradizionali del concetto
                  di famiglia formata da collaboratori che tutti i giorni si
                  mettono in gioco agendo con etica, professionalità,
                  determinazione, responsabilità e disciplina assicurando a chi
                  investe con noi il rispetto degli accordi presi e della parola
                  data.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-duration="1500"
            >
              <img
                src={trustImg}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>

            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-right"
              data-aos-duration="1500"
            >
              <img
                src={City}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>
            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h2 className="text-center color-title-chiaro bold-600 fs-1 mb-4 red-logo">
                  Innovazione
                </h2>
                <p className="text-white fs-5 w-100">
                  La nostra Mission deriva da una grande passione, che ci ha
                  portato a creare un sistema innovativo che integra la realtà
                  degli investimenti con quella immobiliare.
                </p>
              </div>
            </Col>

            <Col
              className="text-white text-center d-flex flex-column justify-content-start align-items-center mb-5  p-4 rounded "
              lg={6}
              md={12}
              sm={12}
              xs={12}
              data-aos="zoom-in"
              data-aos-offset="300"
              data-aos-duration="2000"
            >
              <div className="d-flex justify-content-center align-items-center flex-column w-100">
                <h2 className="text-center  fs-1 mb-4 color-title-chiaro bold-600">
                  Sicurezza
                </h2>
                <p className="text-white fs-5 w-100">
                  La nostra priorità è quella di portare a termine con successo
                  ogni singolo investimento immobiliare, puntando alla
                  massimizzazione dei profitti e all'ottimizzazione della
                  marginalità.
                  <br /> Crediamo fermamente che il successo di un investimento
                  non si misuri solo dai risultati economici, ma anche dalla
                  soddisfazione complessiva di tutte le parti coinvolte. Per
                  questo, ci impegniamo a creare un processo trasparente,
                  collaborativo e orientato alla costruzione di valore,
                  assicurando che ogni progetto rappresenti un'esperienza
                  positiva e gratificante per tutti i soggetti interessati.
                </p>
              </div>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="rounded"
              data-aos="fade-up-left"
              data-aos-offset="300"
              data-aos-duration="1500"
            >
              <img
                src={SecurityInvestimentImg}
                className="w-100 p-4"
                alt="img general contractor"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container className="my-5">
        <h2 className="text-center bold-600 red-logo mb-5 fs-3">
          Riteniamo che la riqualificazione energetica residenziale sia un tema
          cruciale nell’attuale contesto ambientale ed economico.
        </h2>
        <Row>
          <Col
            className="text-white d-flex flex-column justify-content-center align-items-center "
            lg={6}
            md={12}
            sm={12}
            xs={12}
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
            data-aos-offset="200"
          >
            <p className="mt-3 line-height3 fs-5 text-center colortext-blunav bold-600 p-2">
              Ci impegniamo a riqualificare energeticamente gli immobili sui
              quali interveniamo, migliorando la classe energetica di
              appartenenza per ridurre il fabbisogno energetico e abbattere le
              emissioni nell'ambiente. Grazie all'impegno per la riduzione delle
              emissioni di carbonio associate al consumo di energia, promuoviamo
              l'implementazione di pratiche di efficienza energetica, l'utilizzo
              di tecnologie con sistemi avanzati e lo sviluppo di energie
              rinnovabili.
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex flex-column justify-content-center align-items-center"
            data-aos="fade-up"
            data-aos-duration="3000"
            data-aos-offset="200"
          >
            {" "}
            <img
              src={bgClassEnergy}
              alt="img presentazione "
              className="rounded w-70 mb-4 shadow "
            />
          </Col>
        </Row>
      </Container>
      <Container fluid className="overflowHidden py-5 shadow bg-white">
        <Container
          className="overflowHidden  "
          data-aos="fade-left"
          data-aos-offset="300"
          data-aos-duration="1500"
        >
          <h2 className="text-center bold-600 pt-5 mb-5 red-logo">
            Proteggere il capitale
          </h2>
          <p className="text-center  mb-5 fs-5 colortext-blunav ">
            Siamo convinti che gli investimenti immobiliari possano contribuire
            a migliorare la stabilità econimica delle persone, delle famiglie e
            delle future generazioni. Per questo il nostro approccio è orientato
            alla protezione e valorizzazione del capitale investito.
          </p>
        </Container>
      </Container>

      <CallToActionNumberGreen />
      <ContactFormSmall />
      <Footer />
    </>
  );
};
export default LaNostraMission;

/* <Container
        fluid
        className=" py-5 color-blunav overflowHiddenSecondaryPageIntro"
      >
        <h1 className=" text-center text-white fw-bold">
          Investi in sicurezza nei nostri progetti nel Real Estate. <hr />
          Investimenti mirati a creare valore.
        </h1>
        <p className=" p-4 fs-5 text-white  text-center">
          <span className=" fw-bold fs-3 d-block">
            Scegli il mercato Immobiliare per il suo rapporto esclusivo rischio
            rendimento.
          </span>{" "}
          Se vuoi trasformare un investimento improvvisato in un investimento
          conveniente, profittevole e professionale; devi arginare i rischi e
          agire con metodo e strategia per massimizzare la redditività.
        </p>
      </Container> */
