import React, { useEffect } from "react";
import { Button, Col, Container, Row, Accordion } from "react-bootstrap";
import NavigationBar from "../Components/NavigationBar";
import Footer from "../Components/Footer";
import ContactFormSmall from "../Components/ContactFormSmall";
import BarraLaterale from "../Components/BarraLaterale";
import Aos from "aos";
import "aos/dist/aos.css";
import "../Style/pageSecondary.css";
import { useNavigate } from "react-router-dom";
import JobIntro from "../asset/lavoraBG-80op.png";
import numberGreen2 from "../asset/numeroVerde1122SviluppoImmobiliare.png";
import ValoriImg from "../asset/bg-introValori.png";

import { Helmet } from "react-helmet";

const LavoraConNoi = () => {
  const navigate = useNavigate();

  const redirectValoriOnClick = () => {
    navigate("/valori");
    window.scrollTo(0, 0);
  };

  const JobPageStyle = {
    backgroundImage: `url(${JobIntro})`, // Utilizza l'immagine di sfondo
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "50vh",
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "3rem",
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <NavigationBar />
      <Helmet>
        <title>Lavora con noi nel settore degli investimenti immobiliari</title>
        <meta
          name="description"
          content="lavora con noi se sei un operatore del settore immobiliare, un architetto, un ingegnere o un geometra con comprovata esperienza cantieristica edile"
        />
        <link
          rel="canonical"
          href="https://www.1122sviluppoimmobiliare.com/lavora-con-noi-nel-settore-degli-investimenti-immobiliari"
        />
      </Helmet>

      <BarraLaterale />
      <Container
        fluid
        style={JobPageStyle}
        className="img-zoom overflowHidden "
      >
        <div
          className="mt-5 pt-5 d-flex flex-column justify-content-center
         align-items-center  text-center fade-in  "
        >
          <h1 className="mt-5 fw-bold  text-white ">LAVORA CON NOI</h1>
        </div>
      </Container>
      <Container fluid className="py-5 color-blunav ">
        <p className="  fs-5 text-white  text-center  p-lg-5">
          Siamo una società immobiliare, specializzata nell’acquisto e
          rivalorizzazione di Asset immobiliari, cosi da offrire importanti
          rendimenti ai nostri investitori; siamo focalizzati sulla
          sostenibilità e impegnati a fornire ai nostri investitori e clienti un
          servizio di alta qualità. Siamo alla ricerca di persone motivate che
          si uniscano al nostro team e ci aiutino a raggiungere i nostri
          obiettivi.
        </p>
      </Container>

      <Container fluid className="overflowHidden">
        <Container>
          <Row>
            <Col
              className="text-white text-center d-flex flex-column justify-content-center align-items-center mb-5  rounded  "
              lg={6}
              md={12}
              sm={12}
              xs={12}
            >
              <p className="colortext-blunav fw-bold fs-5  line-height3 ">
                Se sei un operatore del settore, un architetto, un ingegnere o
                un geometra con comprovata esperienza cantieristica edile, e sei
                appassionato del settore immobiliare, inviaci il tuo CV via
                email e scopri cosa possiamo offrirti.
              </p>
              <span
                className="text-center fw-bold  fs-5 mb-4 red-logo"
                data-aos="fade-up-right"
                data-aos-duration="2000"
              >
                info@1122sviluppoimmobiliare.com
              </span>
            </Col>
            <Col
              lg={6}
              md={12}
              sm={12}
              xs={12}
              className="d-flex justify-content-center align-items-center"
              data-aos="fade-up-left"
              data-aos-offset="200"
              data-aos-duration="2000"
            >
              <img
                src={numberGreen2}
                className="w-70 p.4"
                alt="img general contractor"
              />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container
        fluid
        className=" d-flex justify-content-center align-items-center py-5 bg-bluScuro "
      >
        <Container>
          <p
            className="text-white  fs-4  line-height3 text-center"
            data-aos="fade-right"
            data-aos-offset="200"
            data-aos-duration="2000"
          >
            Comprendiamo che la flessibilità del lavoro è importante per un sano
            equilibrio tra lavoro e vita privata. <hr /> Pertanto, offriamo
            giorni e orari di lavoro flessibili per garantire che i nostri
            collaboratori possano godere di una buona qualità della vita mentre
            raggiungono i loro obiettivi professionali.
          </p>
        </Container>
      </Container>
      <Container fluid className=" overflowHidden">
        <h5 className="text-center bold-600 fs-2 p-4 red-logo">
          Unisciti al nostro team per fare la differenza nel mondo immobiliare e
          creare un futuro sostenibile per le generazioni future!
        </h5>
        <Row>
          <Col
            data-aos="fade-up-right"
            data-aos-duration="1500"
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="text-center flex-column d-flex align-items-center justify-content-center  "
          >
            <p className="p-2 lineHeight2 width100Smarphone width70Pc colortext-blunav bold-600  p-3 fs-5 lineHeight2">
              Ti invitiamo a visionare "I NOSTRI VALORI". <hr /> Se condividi
              questi valori e trovi che ti rappresentino, inoltraci la tua
              proposta di collaborazione, saremo lieti di valutarla.
            </p>
            <div className="my-3 " data-aos="fade-up" data-aos-duration="2000">
              <Button
                onClick={redirectValoriOnClick}
                className="button-redLogo fs-5 "
              >
                Scopri di più
              </Button>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="rounded"
            data-aos="fade-up-left"
            data-aos-duration="1500"
          >
            <img
              src={ValoriImg}
              className="w-100 p-4"
              alt="img general contractor"
            />
          </Col>
        </Row>
      </Container>
      <ContactFormSmall />
      <Footer />
    </>
  );
};
export default LavoraConNoi;
