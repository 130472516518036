import React from "react";
import Card from "react-bootstrap/Card";
import article24Img from "../../asset/articolo2.png";

import { Button, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function Article24() {
  const navigate = useNavigate();

  const redirectArticle24 = () => {
    navigate("/lavori-per-aumentare-rendita-catastale-immobile");
    window.scrollTo(0, 0);
  };
  return (
    <Col lg={4} md={6} sm={12} xs={12} className="mb-5">
      <Card className="text-center card-style shadow">
        <Card.Img className="img-card" variant="top" src={article24Img} />
        <Card.Body className="d-flex flex-column justify-content-around">
          <Card.Title className="colortext-blunav">
            Rendita catastale, sei lavori che possono farla cambiare
          </Card.Title>

          <Button onClick={redirectArticle24} className="button-linkStyle">
            Leggi l'articolo
          </Button>
        </Card.Body>
      </Card>
    </Col>
  );
}

export default Article24;
