import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../asset/logo-srl-png.png";
import "../Style/navbar.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import { RxHamburgerMenu } from "react-icons/rx";
import OffCanvasProfile from "../Components/OffCanvasProfile";
import ScrollToContactButton from "./ScrollToContactButton";

function NavigationBar() {
  const navigate = useNavigate();

  const redirectHomeOnClick = () => {
    navigate("/");
  };

  const redirectChiSiamoOnClick = () => {
    navigate("/chiSiamo");
    window.scrollTo(0, 0);
  };
  const redirectinvestOnClick = () => {
    navigate("/investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  const redirectFaqOnClick = () => {
    navigate("/faq-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };
  const redirectMissionOnClick = () => {
    navigate("/laNostraMission-rendita-passiva");
    window.scrollTo(0, 0);
  };
  const redirectValoriOnClick = () => {
    navigate("/valori");
    window.scrollTo(0, 0);
  };
  const redirectContattiOnClick = () => {
    navigate("/contatti");
    window.scrollTo(0, 0);
  };
  const redirectWorkOnClick = () => {
    navigate("/lavora-con-noi-nel-settore-degli-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };
  const redirectBlogOnClick = () => {
    navigate("/articoli-investimenti-immobiliari");
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Navbar
        expand="lg"
        className="background-navbar px-4 d-flex flex-column"
        fixed="top"
      >
        <div>
          {" "}
          <ScrollToContactButton />{" "}
        </div>
        <Container fluid>
          <Navbar.Brand href="#">
            <img
              onClick={redirectHomeOnClick}
              className="logo-navbar "
              src={logo}
              alt="logo"
            />
          </Navbar.Brand>

          <Navbar.Toggle className="toggle-white">
            <RxHamburgerMenu className="text-white" />
          </Navbar.Toggle>
          <Navbar.Collapse>
            <Nav className="me-auto my-2 my-lg-0 ">
              <Nav.Link className="linkNav" href="/">
                Home
              </Nav.Link>

              <Nav.Link onClick={redirectChiSiamoOnClick} className="linkNav">
                Chi siamo
              </Nav.Link>
              <Nav.Link onClick={redirectinvestOnClick} className="linkNav">
                Investi con noi
              </Nav.Link>
              <Nav.Link className="linkNav" onClick={redirectMissionOnClick}>
                La nostra mission
              </Nav.Link>
              <Nav.Link className="linkNav" onClick={redirectValoriOnClick}>
                I nostri valori
              </Nav.Link>
              <Nav.Link className="linkNav" onClick={redirectBlogOnClick}>
                Approfondimenti
              </Nav.Link>
              <Nav.Link onClick={redirectFaqOnClick} className="linkNav">
                FAQ
              </Nav.Link>

              <NavDropdown
                title="Contatti"
                id="basic-nav-dropdown"
                className="linkNav"
              >
                <NavDropdown.Item onClick={redirectContattiOnClick}>
                  Contatti
                </NavDropdown.Item>
                <NavDropdown.Item onClick={redirectWorkOnClick}>
                  Lavora con noi
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            <OffCanvasProfile />
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavigationBar;
