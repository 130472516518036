import React, { useEffect } from "react";
import {
  Container,
  Col,
  Card,
  Row,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import "../Style/pageSecondary.css";
import Aos from "aos";
import "aos/dist/aos.css";
import ImgArtcleADN from "../asset/articoloADNKronos.png";
import LogoADN from "../asset/LOGOAdnkronos.png";
import { FaArrowRight } from "react-icons/fa";
import ImgArtcleCDE from "../asset/ScreenshotArticoloCorrireEconomia.png";
import LogoCDE from "../asset/LogoCDE.png";
import ImgArticolo3 from "../asset/Screenshot-articolo-3.png";

const ArticoliGiornalistici = () => {
  const handleButtonClickADNarticle = () => {
    window.open(
      "https://www.adnkronos.com/immediapress/1122-sviluppo-immobiliare-milano-con-invest-app-il-flipping-immobiliare-diventa-piu-easy-e-smart_4ieQ1OGeBoHLINeR3yEkwL",
      "_blank"
    );
  };

  const RedirectToCDEarticle = () => {
    window.open(
      "https://www.corrieredelleconomia.it/2024/07/12/11-22-sviluppo-immobiliare-milano-flipping-immobiliare-opportunita-di-investimento-vantaggiose-nel-real-estate/",
      "_blank"
    );
  };

  const RedirectToCDEarticle2 = () => {
    window.open(
      "https://www.corrieredelleconomia.it/2025/02/05/11-22-sviluppo-immobiliare-milano-il-flipping-come-opportunita-di-investimento/",
      "_blank"
    );
  };

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <>
      <Container
        fluid
        className=" bg-white py-2 my-5 flex-column d-flex align-items-center justify-content-center shadow overflowHidden"
      >
        <h2
          className="mt-5 text-center bold-600 fst-italic colortext-blunav"
          data-aos="fade-left"
          data-aos-duration="800"
        >
          Leggi gli articoli che parlano di noi e scopri di più sul "flipping
          immobiliare"
        </h2>
        <Row className="d-flex align-items-center justify-content-center pointer flex-column  ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex flex-column gap-3 align-items-center justify-content-center pointer"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <div className="d-flex align-items-center justify-content-center ">
              <img src={LogoADN} alt="" className="w-70" />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img src={ImgArtcleADN} alt="" className="w-100" />
            </div>
            <Button
              className=" button-linkStyle fs-3 mb-5 mt-2"
              onClick={handleButtonClickADNarticle}
            >
              Leggi l'articolo
              <FaArrowRight className="ms-1" />
            </Button>
          </Col>

          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex flex-column gap-3 align-items-center justify-content-center pointer"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <div className="d-flex align-items-center justify-content-center mb-3 mt-5">
              <img src={LogoCDE} alt="" className="w-70" />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img src={ImgArtcleCDE} alt="" className="w-100" />
            </div>
            <Button
              className="mb-5 button-linkStyle fs-3 mt-2 "
              onClick={RedirectToCDEarticle}
            >
              Leggi l'articolo
              <FaArrowRight className="ms-1" />
            </Button>
          </Col>

          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className="d-flex flex-column gap-3 align-items-center justify-content-center pointer"
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-easing="linear"
          >
            <div className="d-flex align-items-center justify-content-center mb-3 mt-5">
              <img src={LogoCDE} alt="" className="w-70" />
            </div>
            <div className="d-flex align-items-center justify-content-center">
              <img src={ImgArticolo3} alt="" className="w-100" />
            </div>
            <Button
              className="mb-5 button-linkStyle fs-3 mt-2 "
              onClick={RedirectToCDEarticle2}
            >
              Leggi l'articolo
              <FaArrowRight className="ms-1" />
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default ArticoliGiornalistici;
