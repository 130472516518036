import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const endpoint = process.env.REACT_APP_SERVER_BASE_URL;

const initialState = {
  usersArray: [],
  user: null,
  status: "idle",
  singleUser: {},
  verificationStatus: null, // New state for email verification status
  verificationError: null, // New state for verification errors
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    filterUserSurname: (state, action) => {
      state.usersArray = state.usersArray.filter((user) => {
        return user.surname
          .toLowerCase()
          .includes(action.payload.toLowerCase());
      });
    },
    resetVerificationStatus: (state) => {
      state.verificationStatus = null; // Reset the verification status
      state.verificationError = null; // Reset the verification error
    },
  },
  extraReducers: (builder) => {
    builder
      //Chiamata POST PER CREARE UTENTE
      .addCase(userPost.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(userPost.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(userPost.pending, (state, action) => {
        state.status = "pending";
      })

      //Chiamata GET PER AVERE TUTTI GLI UTENTI
      .addCase(getUsers.fulfilled, (state, action) => {
        state.usersArray = action.payload;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(getUsers.pending, (state, action) => {
        state.status = "pending";
      })

      //Chiamata per cercare utente tramite ID
      .addCase(userById.fulfilled, (state, action) => {
        state.singleUser = action.payload;
      })
      .addCase(userById.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(userById.rejected, (state, action) => {
        state.status = "error";
      })

      //CHIAMATA PATCH PER MODIFICARE UTENTE
      .addCase(updateUser.fulfilled, (state, action) => {
        // Trova l'utente nell'array degli utenti e aggiorna i suoi dati
        const index = state.usersArray.findIndex(
          (user) => user._id === action.payload._id
        );
        if (index !== -1) {
          state.usersArray[index] = action.payload;
        }
        // Imposta lo status a "idle" per indicare che la chiamata è stata completata
        state.status = "idle";
      })
      .addCase(updateUser.rejected, (state, action) => {
        // In caso di errore, impostiamo lo status a "error"
        state.status = "error";
      })
      .addCase(updateUser.pending, (state, action) => {
        // Inizia a caricare, impostando lo status a "pending"
        state.status = "pending";
      })

      //CHIAMATA PATCH PER MODIFICARE SOLO L'IMMAGINE DEL PROFILO UTENTE
      .addCase(updateImageProfileUser.fulfilled, (state, action) => {
        const updateUser = action.payload; // Dati dell'investimento aggiornato
        const index = state.usersArray.findIndex(
          (user) => user._id === updateUser._id
        );
        if (index !== -1) {
          state.usersArray[index] = updateUser; // Aggiorna l'investimento nell'array
        }
        state.status = "idle";
      })
      .addCase(updateImageProfileUser.rejected, (state, action) => {
        state.status = "error"; // Gestisci lo stato di errore
      })
      .addCase(updateImageProfileUser.pending, (state, action) => {
        state.status = "pending"; // Gestisci lo stato di caricamento
      })

      //CHIAMATA DELETE PER CANCELLARE UTENTE
      .addCase(deleteUser.fulfilled, (state, action) => {
        // Rimuovi l'utente dall'array degli utenti
        state.usersArray = state.usersArray.filter(
          (user) => user._id !== action.payload
        );
        // Imposta lo status a "idle" per indicare che la chiamata è stata completata
        state.status = "idle";
      })
      .addCase(deleteUser.rejected, (state, action) => {
        // In caso di errore, impostiamo lo status a "error"
        state.status = "error";
      })
      .addCase(deleteUser.pending, (state, action) => {
        // Inizia a caricare, impostando lo status a "pending"
        state.status = "pending";
      })
      // CHIAMATA CHE INVIA MAIL E TOKEN PER CAMBIARE PASSWORD
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(forgotPassword.pending, (state, action) => {
        state.status = "pending";
      })

      // CHIAMTA CHE CAMBIA LA PASSWORD
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "idle";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "error";
      })
      .addCase(resetPassword.pending, (state, action) => {
        state.status = "pending";
      })
      // Chiamata per la verifica dell'email
      .addCase(verifyEmail.fulfilled, (state, action) => {
        // Gestisci la logica per quando l'email è verificata con successo
        state.status = "idle";
        // Puoi anche voler gestire eventuali stati o messaggi di successo qui
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.status = "error";
        // Gestisci l'errore di verifica dell'email qui
      })
      .addCase(verifyEmail.pending, (state, action) => {
        state.status = "pending";
      });
  },
});

//Chiamata POST PER LA REGISTAZIONE UTENTE
export const userPost = createAsyncThunk("register/users", async (user) => {
  const form = new FormData();
  form.append("role", user.role);
  form.append("name", user.name);
  form.append("surname", user.surname);
  form.append("phoneNumber", user.phoneNumber);
  form.append("password", user.password);
  form.append("email", user.email);
  form.append("address", user.address);
  form.append("city", user.city);
  form.append("gender", user.gender);
  form.append("birthdayDate", user.birthdayDate);
  form.append("avatar", user.avatar);
  form.append("experienceInvestiment", user.experienceInvestiment);
  form.append("motivationToInvestiment", user.motivationToInvestiment);
  form.append("contactSociety", user.contactSociety);
  form.append("dispositionCapital", user.dispositionCapital);
  form.append("recaptchaToken", user.recaptchaToken); // Aggiungi il token reCAPTCHA qui

  try {
    const res = await axios.post(` ${endpoint}/users/register`, form, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

// Chiamata GET per verificare l'email della registrazione
export const verifyEmail = createAsyncThunk("verify/email", async (token) => {
  try {
    const res = await axios.get(`${endpoint}/users/verify-email/${token}`);
    return res.data; // Restituisci i dati della risposta
  } catch (error) {
    throw error.response.data; // Propaga l'errore
  }
});

//CHIAMATA GET PER AVERE TUTTI GLI UTENTI
export const getUsers = createAsyncThunk("users/all", async () => {
  try {
    const res = await axios.get(`${endpoint}/users/all`);
    if (!res) {
      console.log(`HTTP error! status: ${res.status}`);
    }
    console.log("LOG GET ASSOCIATION SLICE", res.data);
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
});

//Chiamata GET by ID dell'utente
export const userById = createAsyncThunk("users/getById", async (id) => {
  try {
    const res = await axios.get(`${endpoint}/users/` + id);
    if (!res) {
      console.log(`HTTP error! status: ${res.status}`);
    }
    console.log(res.data);
    return res.data;
  } catch (error) {
    console.log(error);
  }
});

// CHIAMATA PATCH PER MODIFICARE INFO UTENTE
export const updateUser = createAsyncThunk(
  "users/update",
  async ({ id, updates }) => {
    try {
      const res = await axios.patch(`${endpoint}/users/${id}`, updates);
      return res.data; // Restituisci i dati dell'utente aggiornati per la gestione nell'extraReducers
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

// CHIAMATA PATCH PER AGGIORNARE SOLO L'IMMAGINE DEL PROFILO DELL'UTENTE
export const updateImageProfileUser = createAsyncThunk(
  "user/updateImageProfileUser",
  async ({ id, avatar }) => {
    const form = new FormData();
    form.append("avatar", avatar);

    try {
      const res = await axios.patch(
        `${endpoint}/users/${id}/updateImageProfileUser`,
        form,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      return res.data; // Restituisci i dati dell'investimento aggiornato per la gestione nell'extraReducers
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

//CHIAMATA DELETE PER CANCELLARE UTENTE
export const deleteUser = createAsyncThunk("users/delete", async (id) => {
  try {
    const res = await axios.delete(`${endpoint}/users/${id}`);
    return id; // Restituisci l'ID dell'utente eliminato per la gestione nell'extraReducers
  } catch (error) {
    console.error(error);
    throw error;
  }
});

// CHIAMATA POST PER RICHIEDERE IL RESET DELLA PASSWORD
export const forgotPassword = createAsyncThunk(
  "users/forgotPassword",
  async (email) => {
    try {
      const res = await axios.post(`${endpoint}/users/forgot-password`, {
        email,
      });
      return res.data;
    } catch (error) {
      console.error(error);
      throw error; // Lancia l'errore qui
    }
  }
);

// CHIAMATA POST PER REIMPOSTARE LA PASSWORD
export const resetPassword = createAsyncThunk(
  "users/resetPassword",
  async ({ token, password }) => {
    try {
      const res = await axios.post(
        `${endpoint}/users/reset-password/${token}`,
        { password }
      );
      return res.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const { filterUserSurname, resetVerificationStatus } = userSlice.actions;
export default userSlice.reducer;
